.modal-bg {
    /* border: 3px solid blue; */
    background-color: rgb(0 0 0 / 0.7);
    position: fixed;
    height: 100%;
    width: 100%;
    transition: all 0.2s ease-in;
    transition: opacity 0.25s ease;
    z-index: 20;
    opacity: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top: 20vh; */
}

.modal-wrapper {
    background-color: var(--clr-neutral-200);
    min-height: 40%;
    width: 100%;
    margin-inline: 1rem;
    max-width: 40em;
    border-radius: 0.25rem 0.25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.fadeOut{
    opacity:0;
    pointer-events: none;
    /* width:0; */
    /* height:0; */
    transition: opacity 0.4s;

}

.fadeIn{
    opacity:1;
    /* width:100px; */
    /* height:100px; */
    transition:  opacity 0.4s 0.4s;

}

.modal-title {
    font-size: var(--fs-800);
    font-weight: var(--fw-bold);
    color: var(--clr-primary-700);

}
.google-image-wrapper {
    background-color: rgba(.42, .45, .49, 0.0);
    border: none;
    height: 10vh;
}

.google-image {
    width: auto;
    margin-inline: auto;
    max-height: 100%;
}

.modal-subtitle {
    /* font-size: 16px; */
    /* color: #101c4f; */
    text-align: center;
}
.modal-redirect {
    font-size: var(--fs-300);
    color: var(--clr-neutral-300);
}