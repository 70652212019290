.wrapper {
    /* border: 3px solid hotpink; */
    max-width: 40em;
    margin-inline: auto;
    padding: 0.5rem;
}

.hero {
    /* border: 3px solid blue; */
    width: 50%;
    margin: 0.5rem auto;
}

.main-wrapper {
    background-color: var(--clr-neutral-200);
    border-radius: 0.25rem 0.25rem;
    padding-bottom: 0.5rem;
    /* border-top-right-radius: 0.25rem 0.25rem; */
}

.header {
    margin-top: 1rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    text-align: center;
}

.header__line {
    flex: 1;
    height: 1px;
    background-color: var(--clr-primary-700);
}

.header__title {
    font-family: var(--ff-title);
    font-weight: var(--fw-title);
    font-size: var(--fs-800);
    color: var(--clr-primary-700);
}

.disclaimer {
    text-align: center;
}

.card {
    /* border: 3px solid blue; */
    /* width: 98%; */
    margin-inline: 0.5rem;
    margin-block: 2rem;
    background-color: var(--clr-neutral-100);
    border-radius: 0.25rem 0.25rem;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.05); */
    display: grid;
    grid-template-columns: 5fr 5fr 5fr 1fr;
    grid-auto-rows: 1fr;
    grid-template-areas:
        'one two two .'
        'one three three .'
        'four four four .'
        'four four four five';
}

.card__img {
    grid-area: one;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red;  */

    /* padding: 2rem 1rem; */
}

.profile-pic {
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    max-width: 85%;
    margin-block: 1rem;
}

.myClassName {
    /* background-color: yellow; */
    width: 800px;
}

.myPlaceholderClassName {
    background-color: yellow;
}

.myMenuClassName {
    background-color: red;
    font-weight: var(--fw-normal);
}
.myArrowClassName {
    background-color: blue;
}

.myControlClassName {
    background-color: pink;
}

.card__title {
    grid-area: two;
    /* background-color: red; */
    font-family: var(--ff-title);
    font-weight: var(--fw-title);
    color: var(--clr-primary-700);
    /* padding-top: 1rem; */
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    /* font-size: calc(min(8vw, 80px)); */
}
.card__rating {
    grid-area: three;
    display: flex;
    align-items: flex-start;
}
.card__review {
    grid-area: four;
    padding: 1rem 1rem;
}

.review-input {
    border: none;
    /* outline: none; */
    background-color: var(--clr-neutral-200);
    outline-color: var(--clr-primary-700);
    resize: none;
    width: 100%;
    padding: 1rem;
}
.card__count {
    grid-area: five;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.count-wrapper {
    /* border: 3px solid blue; */
    width: 2.5rem;
    margin-right: 0.5rem;
    text-align: right;
}

.submit-btn-wrapper {
    /* border: 3px solid blue; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.submit-btn {
    width: 50%;
    margin-inline: auto;
    margin-bottom: 1.5rem;
    background-color: var(--clr-primary-700);
    color: var(--clr-neutral-100);
    padding: 0.75rem;
    border-radius: 2.5rem;
    text-align: center;
    font-weight: var(--fw-bold);
}

.footer {
    /* border: 3px solid blue; */
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}

.link {
    font-weight: var(--fw-bold);
    color: var(--clr-primary-700);
    text-decoration: none;
}

.company-name {
    /* border: 3px solid green; */
    font-weight: var(--fw-bold);
    color: var(--clr-neutral-900);
}

.copyright {
    /* border: 3px solid hotpink; */
}

.links-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-around;
}
