/* body {
  margin: 0;
  font-family: 'Merriweather', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
/* Box sizing rules */

/* 
### Primary
- Dark purple: #101c4f
rgb(16, 28, 79)

### Neutral
- White: #ffffff
- Light Grey: #f6f6f6
- Dark Grey: #5e5e5e
- Off Black: #1b1b1b */

:root {

  /* Font Families */
  --ff-primary: "Lusitana", serif;
  --ff-title: "Merriweather", serif;
  
  /* Font Weights */
  --fw-normal: 400;
  --fw-bold: 700;
  --fw-title: 900;

  /* Font Sizes */
  font-size: 16px;

  --fs-300: 1.0rem;
  --fs-400: 1.3125rem;
  --fs-600: 1.75rem;
  --fs-800: 2.3125rem;
  --fs-900: 3.0rem;

  /* Colors */
  --clr-primary-700: #101c4f;

  --clr-neutral-900: #1b1b1b;
  --clr-neutral-400: #5e5e5e;
  --clr-neutral-300: #a3a3a3;

  --clr-neutral-200: #f6f6f6;
  --clr-neutral-100: #ffffff;

}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Prevent font size inflation */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

/* Remove default margin in favour of better control in authored CSS */
body, h1, h2, h3, h4, p,
figure, blockquote, dl, dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  line-height: 1.5;
}

/* Set shorter line heights on headings and interactive elements */
h1, h2, h3, h4,
button, input, label {
  line-height: 1.1;
}

/* Balance text wrapping on headings */
h1, h2,
h3, h4 {
  text-wrap: balance;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  color: currentColor;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input, button,
textarea, select {
  font-family: inherit;
  font-size: inherit;
}

/* Make sure textareas without a rows attribute are not tiny */
textarea:not([rows]) {
  min-height: 10em;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
  scroll-margin-block: 5ex;
}

@media screen and (max-width: 40em) {
  :root {
      --fs-300: 0.8rem;
      --fs-400: 1.0rem;
      --fs-600: 1.25rem;
      --fs-800: 1.5rem;
      --fs-900: 2.5rem;
  }
}

html {
  font-family: var(--ff-primary);
  font-weight: var(--fw-normal);
  line-height: 1.5;
  color: var(--clr-neutral-400);

}

h1, h2,
h3, h4 {
  line-height: 1.2;
}

body {
  background-color: var(--clr-neutral-200);
  font-size: var(--fs-400);

}